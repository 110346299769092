import React, { Component, useEffect } from 'react'
import { scroller, scrollSpy } from 'react-scroll'
import IndexPage from '../containers/IndexPage'
import PrimaryLayout from '../components/layouts/PrimaryLayout'
import pagesInfos from '../config/i18n/pagesInfos'

const Index = ({ pageContext }) => {
  useEffect(() => {
    let hash = window.location.hash
    hash = hash.replace('#', '')

    if (hash && hash != '') {
      console.log('hash : ' + hash)
      scroller.scrollTo(hash)
    }
    scrollSpy.update()
  }, [])
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={true}
      page={pagesInfos.ComponentIndex}
    >
      <IndexPage />
    </PrimaryLayout>
  )
}

export default Index
