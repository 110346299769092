import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import {
  Link as TestLink,
  Element,
  scroller,
  animateScroll,
} from 'react-scroll'
import Img from 'gatsby-image'
import Iframe from 'react-iframe'
import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'
import { cover } from 'polished'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Section from '../components/Section'
import Heading from '../components/Heading'
import Hero from '../components/Hero'
import Button from '../components/Button'
import Team from '../components/Team'
import Gallery from '../components/Gallery'
import Slider from '../components/Slider'
import ShadowWrapper from '../components/ShadowWrapper'
import Map from '../components/Map'
import {
  defaultState,
  slides,
  draggedSpringOptions,
  trailingSpringOptions,
} from '../components/Slider/data'

import GatsbyLink from '../components/Link'

import pagesInfos from '../config/i18n/pagesInfos'

const offset = -10

const useStyles = makeStyles(theme => ({
  eventHeader: {
    height: '150px',
    padding: theme.spacing(2),
    backgroundPosition: 'top',
    borderRadius: '10px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    position: 'relative',
    textTransform: 'uppercase',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  eventHeaderCover: {
    ...cover(),
    backgroundColor: theme.palette.primary.main,
    opacity: 0.6,
    zIndex: -1,
  },
  h3: {
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(6),
  },
  slider: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
  },
  iframe: {
    display: 'block',
    border: 0,
    height: '400px',
    boxShadow:
      '0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6)',
  },
  image: {
    borderRadius: '10px',
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
    //outline: '8px solid ' + theme.palette.primary.main,

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
  imageBackground: {
    position: 'absolute',

    top: offset,
    bottom: offset,
    left: offset,
    right: offset,
    background: '#fff',
    opacity: 1,
    borderRadius: '10px',
    zIndex: -1,

    //border: 'solid 8px ' + theme.palette.secondary.main,
  },
  imageBackground1: {
    position: 'absolute',

    top: offset * 3,
    bottom: offset * 3,
    left: offset * 3,
    right: offset * 3,
    background: '#fafdfd',
    opacity: 0.5,
    borderRadius: '10px',
    zIndex: -2,

    //border: 'solid 8px ' + theme.palette.secondary.main,
  },
}))

const IndexPage = ({}) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const [index, setIndex] = React.useState(0)
  const data = useStaticQuery(graphql`
    query {
      bgCabinet: file(
        relativePath: {
          eq: "home/modern-metallic-dentist-tools-and-burnishers-on-a-6ZPKC39.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const handleClick = () => {
    animateScroll.scrollTo(1000)
  }
  return (
    <React.Fragment>
      <Helmet
        title={t('home:head.title')}
        meta={[
          {
            name: 'description',
            content: t('home:head.description'),
          },
        ]}
      />
      <Element name="accueil">
        <Hero
          title={t('home:header.title')}
          subtitle={t('home:header.subtitle')}
        >
          <Button
            className={classes.button}
            href="//booking.mobminder.com/dentistecrucifix"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('home:header.button')}
          </Button>
        </Hero>
      </Element>
      <Element id="cabinet" name="cabinet">
        <Section bg="#ecf7f7">
          <Grid container justify="space-between">
            <Grid item sm={4} xs={12}>
              <ShadowWrapper>
                <Img
                  fluid={data.bgCabinet.childImageSharp.fluid}
                  className={classes.image}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </ShadowWrapper>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Heading title={t('home:section1.title')} variant="primary" />
              {t('home:section1.paragraphs1', { returnObjects: true }).map(
                paragraph => (
                  <Typography
                    component="p"
                    variant="body2"
                    paragraph
                    key={paragraph}
                  >
                    {paragraph}
                  </Typography>
                )
              )}
              <Link to="/cabinet">
                <Button variant="secondary" className={classes.button}>
                  {t('home:section1.button')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Section>

        <Section>
          <Heading
            title={t('home:section2.title')}
            variant="secondary"
            center
          />
          <Slider />
        </Section>
      </Element>
    </React.Fragment>
  )
}

IndexPage.defaultProps = {}

export default IndexPage
